if (process.env.NODE_ENV === "production") {
  (function (b, i, s, q, u, c, k) {
    b[u] =
      b[u] ||
      function () {
        (b[u].q = b[u].q || []).push(arguments);
      };
    (c = i.createElement(s)), (k = i.getElementsByTagName(s)[0]);
    c.async = 1;
    c.setAttribute("siteId", "e6d547bf-1128-4495-91d6-8652cef62351");
    c.src = q;
    k.parentNode.insertBefore(c, k);
  })(
    window,
    document,
    "script",
    "https://script.sw.report/sw.js",
    "bisquickanalytics"
  );
}
